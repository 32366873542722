global.jQuery = require('jquery');
const $ = jQuery;

require('svg4everybody')();
// const Swiper = require('swiper');
require('venobox');
require('desvg');
require('./_modernizr-custom');


$(function(){

	//Link
	$('a[href^=http]').not('[href*="'+location.hostname+'"]').attr('target', '_blank').addClass('blank');
	$('.wp-block-file a').attr('target', '_blank');

	var headerHeight = $('.header-section').outerHeight();
	var urlHash = location.hash;
	if(urlHash) {
		$('body,html').stop().scrollTop(0);
		setTimeout(function(){
			var target = $(urlHash);
			var position = target.offset().top - headerHeight;
			$('body,html').stop().animate({scrollTop:position}, 500);
		}, 200);
	}
	$('a[href^="#"]').not('.venobox').on('click', function(){
		var href= $(this).attr("href");
		var target = $(href);
		var position = target.offset().top - headerHeight - 100;
		$('body,html').stop().animate({scrollTop:position}, 500);
		return false;
	});


	//Header, Footer
	var header = $('header');
	var position = $('header').offset().top + 200;
	$(this).on('load scroll resize', function(){
		if($(this).scrollTop() > position && header.hasClass('is-fixed') == false) {
			header.addClass('is-fixed');
		}
		else if($(this).scrollTop() < position && header.hasClass('is-fixed') == true){
			header.removeClass('is-fixed');
		}
	});


	//Nav toggle
	$('body').append('<div class="mask"></div>');
	$('#primary-nav').on('click', function(){
		$('body').toggleClass('menu-open');
	});
	$('header .wp-block-buttons').on('click', function(event){
		// $('#primary-nav').trigger('click');
		$('body').removeClass('menu-open');
	});


	//Venobox
	var popupBox = $('.venobox').venobox({
		infinigall: true,
		bgcolor: 'transparent',
	});
	$(document).on('click','.close', function(e){
		popupBox.VBclose();
	});

	//deSVG
	deSVG('figure.svg img', true);


	//scroll animation .group-features .wp-block-image
	function fix_element(){
		var margin = 100;
		var fixnav = $('.fix-nav');
		var fix = $('.fix-element');
		var fixrange = $('.fix-range');
		var fixH = fix.height();
		var fixnavY = fixrange.offset().top;
		var fixnavH = fixrange.height();
		var targetScroll = fixnavY-margin;
		var windowScroll = $(window).scrollTop();
		if(windowScroll > targetScroll){
			if(windowScroll > fixnavY+fixnavH-fixH-margin){
				fix.css({position:'absolute', bottom:0}).removeClass('is-absolute').removeClass('is-fixed');
			}else{
				fix.css({position:'fixed', bottom:0}).addClass('is-fixed').removeClass('is-absolute');
			}
		}else{
			fix.css({position:'absolute', bottom:0}).addClass('is-absolute').removeClass('is-fixed');
		}
	}
	if($(window).width() > 740) {
		if($('.entry-content .fix-range').length){
			$(window).on('load scroll resize', function() {
				fix_element();
			});
		}
	}


	//fade animation
	function visualAnimation(){
		var visualLogo = $('.edit-front .visual-container .visual-logo');
		var featureContainer = $('.edit-front .visual-container .feature-container p');
		visualLogo.addClass('front-logo-fade');
		featureContainer.css('opacity','1');
		featureContainer.css('transform','translateY(0)');
	}
	function animation(){
		$('.fade').each(function(){
			var targetPosition = $(this).offset().top;
			var scrollHeight = $(window).scrollTop();
			var windowHeight = $(window).height();
			if (scrollHeight > targetPosition - windowHeight){
				$(this).css('opacity','1');
				$(this).css('transform','translateY(0)');
			}
		});
	}
	$(window).on('load', function(){
		visualAnimation();
	});

	animation();
	$(window).scroll(function(){
		animation();
	});


});

